import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
// @ts-ignore
import * as styles from "../components/index.module.css"
import { Link } from "gatsby"
import { DonateButton } from "../components/paypal"
import { UpBookIcon, EmailCheckIcon } from "../components/icon"

const PriorityCard = ({ title, children }) => {
  return (
    <li className={styles.listItem}>
      <span className={styles.listItemLink}>{title}</span>
      <p className={styles.listItemDescription}>{children}</p>
    </li>
  )
}

const IndexPage = () => (
  <Layout>
    <Seo title="Kyle Kelley for Santa Cruz City Schools" />
    <div className={styles.textCenter}>
      <UpBookIcon />

      <h1>
        <b>Kyle Kelley</b> for <b>Santa Cruz City Schools</b>
      </h1>
      <p className={styles.intro}>
        I’m <b>Kyle&nbsp;Ray&nbsp;Kelley</b>, a&nbsp;parent at Bay&nbsp;View
        Elementary, a&nbsp;husband, and&nbsp;a&nbsp;community&nbsp;advocate.
        <br />
        It would be a great honor to serve the Santa Cruz City School district
        as a Trustee.
      </p>
    </div>
    <ul className={styles.list}>
      <PriorityCard title="Diversify funding for our school districts">
        I will work together with my colleagues, the district, parents,
        students, and the legislature to find more ways to fund our schools.
      </PriorityCard>

      <PriorityCard title="Spend funds effectively">
        Our little school district has to be incredibly smart about how it uses
        limited funds.
      </PriorityCard>

      <PriorityCard title="Establish an excellent universal Pre-K program">
        Studies show that children who attend preschool have enhanced brain
        development and improved learning outcomes as they begin their academic
        journeys.
        {/*Under recent legislation, California schools are required to
        plan for and implement universal Pre-K by 2025.*/}
        {/*Our district must be a leader in providing free, high-quality, inclusive
        pre-kindergarten for all four-year olds.*/}
      </PriorityCard>

      <PriorityCard title="Recruit and retain high quality teachers and support staff">
        Our community is whole when we can bring in the best teachers and keep
        them here in our district.
      </PriorityCard>
    </ul>

    <div className={styles.textCenter}>
      <StaticImage
        src="../images/kyle-family-couch.png"
        placeholder="dominantColor"
        loading="lazy"
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt="Kyle Kelley with his toddler and eldest daughter"
        style={{ marginBottom: `var(--space-3)` }}
      />
    </div>

    <div className={styles.textCenter}>
      <p className={styles.intro}>
        I'm running for <b>District 2</b> which covers portions of Downtown,
        Beach Flats, and the lower Westside. Santa Cruz City Schools's Board of
        Eduction elects representatives within{" "}
        <Link to="/trustee-areas">Trustee Areas</Link>.
      </p>
      <StaticImage
        src="../images/district-2.png"
        placeholder="tracedSVG"
        loading="lazy"
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt="map of district two"
        style={{ marginBottom: `var(--space-3)` }}
      />
    </div>

    <div className={styles.textCenter}>
      <a href="mailto:kyleforsantacruz@gmail.com">
        <EmailCheckIcon />
      </a>
      <p className={styles.intro}>
        Email the campaign at{" "}
        <a href="mailto:kyleforsantacruz@gmail.com">
          kyleforsantacruz@gmail.com
        </a>{" "}
        to stay in touch and lend your support.
      </p>
    </div>
    <div
      className={styles.textCenter}
      style={{
        marginTop: `var(--space-5)`,
      }}
    >
      <DonateButton />
    </div>
  </Layout>
)

export default IndexPage
