import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const BACKGROUND_COLOR = `#0f67ff`

const defaultProps: React.ComponentProps<typeof StaticImage> = {
  src: "../images/plain-email-check.png",
  loading: "eager",
  placeholder: "none",
  width: 64,
  quality: 95,
  formats: ["auto", "webp", "avif"],
  alt: "icon",
  backgroundColor: BACKGROUND_COLOR,
  style: {
    borderRadius: "50%",
    WebkitBorderRadius: "50%",
    MozBorderRadius: "50%",
    marginBottom: `var(--space-3)`,
  },
}

export function UpBookIcon() {
  return (
    <StaticImage
      className="circleicon"
      {...defaultProps}
      src="../images/plain-up-book.png"
    />
  )
}

export function EmailCheckIcon() {
  return (
    <StaticImage
      className="circleicon"
      {...defaultProps}
      src="../images/plain-email-check.png"
    />
  )
}
